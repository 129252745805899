<template>
  <nav class="navbar">
    <div class="navbar-brand">
        <router-link class="navbar-item" to="/">
            <v-icon class="icon" name="poll-h"/>
            <b style="font-size:20px;">Touchpoints POE Survey Processor</b>
            <span class="version" v-if="$can('manage','Admin')">{{appVersion}}</span>
        </router-link>
      <!-- Menu button, only shown for tablet and smaller -->
        <a
            role="button"
            class="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar"
            @click="showNav = !showNav"
            :class="{ 'is-active': showNav }"
        >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>
    <div id="navbar" class="navbar-menu" :class="{ 'is-active': showNav }">
        <div class="navbar-start"></div>
        <div class="navbar-end has-text-right">
            <div class="navbar-item" v-if="isAuthenticated">{{email}}</div>
            <div class="navbar-item">
                <b-dropdown v-if="documents.length > 1" aria-role="list" position="is-bottom-left">
                    <button class="button is-primary" slot="trigger">
                        <v-icon name="question-circle"/>
                    </button>
                    <b-dropdown-item v-for="document in documents"
                                     :key="document.id"
                                     aria-role="listitem">
                        <a :href="document.url" target="_blank">
                            {{document.name}}
                        </a>
                    </b-dropdown-item>
                </b-dropdown>
                <a v-else-if="documents.length > 0" :href="documents[0].url" target="_blank">
                    <v-icon name="question-circle"/>
                </a>
            </div>
            <div class="navbar-item" v-if="isAuthenticated">
                <a @click="logout()">
                  <u>Logout</u>
                </a>
            </div>
        </div>
    </div>
  </nav>
</template>

<script>
import { EventBus } from '@/event-bus';
import constants from '@/constants';
import documentService from '@/services/document-service';

export default {
    // name: 'PgNavbar',
    data () {
        return {
            appVersion: constants.APP_VERSION,
            showNav: false,
            isAuthenticated: localStorage.getItem('jwt') !== null,
            email: localStorage.getItem('email') || '',
            documents: []
        };
    },
    // created: function() { // this has to use the old function defn as per https://github.com/vuejs-templates/browserify-simple/issues/6#issuecomment-214003282
    created () { // or this new ES6 approach. Can't use arrow functions
        if (this.isAuthenticated) {
            this.extractDocuments();
        }
        EventBus.$on('loggedIn', (authenticated) => {
            this.isAuthenticated = authenticated;
            this.email = localStorage.getItem('email') || '';

            if (authenticated) {
                this.extractDocuments();
            } else {
                this.documents = [];
            }
        });

        EventBus.$on('logout', () => {
            this.logout();
        });

        EventBus.$on('documentsChanged', (documents) => {
            this.documents = documents;
        });
    },
    methods: {
        logout (e) {
            if (e) { e.preventDefault(); }
            localStorage.clear();
            EventBus.$emit('loggedIn', false);
            this.$router.push('/login');
        },

        extractDocuments () {
            documentService.getAll().then(
                response => {
                    this.documents = response.data || [];
                },
                error => {
                    if (error.response && error.response.data) {
                        this.errorMessage = error.response.data;
                    } else {
                        this.errorMessage = error.message;
                    }
                }
            );
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navbar {
    // background: $panel-background;
    background: $primary;
    height: 52px;
    .icon {
      margin: .25em;
    }
}

.version {
    padding-left: 10px;
    color: $primary-invert;
}

.navbar .navbar-item {
    color: $primary-invert;

    a {
      color: $primary-invert;
      cursor: pointer;
    }

    &:hover {
        background-color:transparent;
    }

    .dropdown-menu {
        a {
            text-align: left;
            color: $primary;
            cursor: pointer;
        }
    }
}
</style>
