<template>
    <section class="section">
        <div class="content has-text-grey has-text-centered">
            <img :src="require('@/assets/publicis.png')" width="80"/>
            <p>No results found</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'EmptyTableContent'
};
</script>
