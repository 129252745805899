import moment from 'moment';

class DateHelperService {
    // Only use when you want to convert a date's value to be a UTC date and time does not matter
    convertToUTC (date) {
        const originalDate = moment(date).format('YYYY-MM-DD');
        return new Date(moment.utc(originalDate, 'YYYY-MM-DD'));
    }

    convertToLocal (utcDate) {
        const originalDate = moment.utc(utcDate).format('YYYY-MM-DD');
        return new Date(moment(originalDate, 'YYYY-MM-DD'));
    }

    areEqual (date1, date2) {
        // Comparing UTC only to check whether two dates are actually same moments in time
        // independent of timezones
        return date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate();
    }

    formatForClient (date) {
        if (date) {
            // Parse the date with moment.utc so the UTC date will be always displayed regardless of timezone.
            return moment.utc(date).format('DD-MMM-YYYY');
        }
    }

    formatForClientLocale (date) {
        if (date) {
            // Parse the date with moment so the date in the local timezone will be displayed.
            return moment(date).format('DD-MMM-YYYY');
        }
    }

    formatForAPI (date) {
        return date.toISOString();
    }

    customFormat (date, format) {
        if (date && format) {
            return moment(date).format(format);
        }
    }
};
export const dateHelperService = new DateHelperService();
