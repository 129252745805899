<template>
  <div id="app"  class="full-height ">
        <pg-navbar/>
        <!-- With the key, any change to the path will trigger a reload of the component with the new data -->
        <router-view :key="$route.fullPath" class="view-container"/>
    </div>
</template>

<script>
import PgNavbar from '@/components/PgNavbar.vue';

export default {
    name: 'App',
    components: {
        PgNavbar
    }
};
</script>

<style lang="scss">
@import "assets/scss/main.scss";
</style>
