import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isSurveyDetailComplete: false,
        isSurveyDefinitionComplete: false,
        isSurveyFileComplete: false,
        isSurveyXmlComplete: false
    },
    mutations: {
        setIsSurveyDetailComplete (state, isComplete) {
            state.isSurveyDetailComplete = isComplete;
        },
        setIsSurveyDefinitionComplete (state, isComplete) {
            state.isSurveyDefinitionComplete = isComplete;
        },
        setIsSurveyFileComplete (state, isComplete) {
            state.isSurveyFileComplete = isComplete;
        },
        setIsSurveyXmlComplete (state, isComplete) {
            state.isSurveyXmlComplete = isComplete;
        }
    },
    actions: {
    },
    modules: {
    }
});
