<template>
  <div class="hello">

    <b-notification type="is-danger" v-if="errorMessage" :closable="false">
        <i class="fas fa-exclamation-circle"></i> {{ errorMessage }}
    </b-notification>

    <!-- Search input -->
      <b-field>
          <b-input
              rounded
              v-model="filterTerm"
              placeholder="Filter Surveys..."
              icon="search"
          >
          </b-input>
      </b-field>
    <!-- Survey Table -->
    <b-table
        class="box"
        :data="filteredSurveys"
        :paginated="true"
        :per-page="surveysPerPage"
        :current-page.sync="currentPage"
        :pagination-simple="false"
        :pagination-position="'bottom'"
        :default-sort-direction="'asc'"
        :striped="true"
        :loading="isLoading"
        default-sort="id"
    >
        <template v-slot:bottom-left>
            {{pageInfo}}
        </template>

        <template>
            <b-table-column field="name" label="Name" sortable v-slot="props">
                {{props.row.name}}
            </b-table-column>
            <b-table-column field="description" label="Description" sortable v-slot="props">
                {{ props.row.description }}
            </b-table-column>
            <b-table-column field="lastModifiedBy" label="Last Modified By" sortable v-slot="props">
                {{ props.row.lastModifiedBy }}
            </b-table-column>
            <b-table-column field="lastModified" label="Last Modified (UTC)" sortable v-slot="props">
                {{ props.row.lastModified | formatDate }}
            </b-table-column>
            <b-table-column label="" width="50" v-slot="props">
                <b-button
                  v-if="props.row.hasSurveyXmlFile"
                  class="is-primary is-outlined"
                  @click="downloadSurveyXmlFile(props.row.id)"
                  :disabled="isDownloading"
                  title="Download XML">
                    <i class="fas is-small" :class="isDownloading ? 'fa-spinner fa-spin' : 'fa-download'"></i>
                    XML
                </b-button>
            </b-table-column>
            <b-table-column label="" width="100" v-slot="props">
              <div class="buttons">
                <b-button
                  icon-left="edit"
                  class="is-primary is-outlined"
                  tag="router-link"
                  :to="'/survey/'+props.row.id"
                  title="Edit survey"
                  type="is-link">
                </b-button>
                <b-button
                  icon-left="times"
                  class="is-danger is-outlined"
                  @click="showConfirmDeleteModal(props.row)"
                  title="Delete survey">
                </b-button>
              </div>
            </b-table-column>
        </template>
        <!-- Empty Table Template -->
        <template slot="empty">
            <empty-table-content/>
        </template>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash';

import surveyService from '@/services/survey-service.js';
import surveyXmlFileService from '@/services/survey-xml-file-service.js';
import { saveAs } from 'file-saver';

import EmptyTableContent from '@/components/EmptyTableContent.vue';

export default {
    name: 'SurveysTable',
    components: {
        EmptyTableContent
    },
    data () {
        return {
            errorMessage: '',
            isLoading: true,
            isDownloading: false,
            surveys: [],
            filteredSurveys: [],
            filterTerm: '',
            surveysPerPage: 25,
            currentPage: 1
        };
    },
    async created () {
        try {
            const response = await surveyService.getSurveys();
            this.surveys = response.data;
            this.filteredSurveys = response.data;
        } catch (error) {
            this.errorMessage = error.message;
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        debounceFilterSurveys: _.debounce(function () { this.filterSurveys(); }, 250),
        filterSurveys: function () {
            const substring = this.filterTerm.toLowerCase();
            this.filteredSurveys = this.surveys.filter(s => {
                return s.name.toLowerCase().includes(substring) ||
                    s.state.toLowerCase().includes(substring) ||
                    s.description.toLowerCase().includes(substring) ||
                    s.lastModifiedBy.toLowerCase().includes(substring);
            });
        },
        deleteSurvey: async function (survey) {
            try {
                await surveyService.deleteSurvey(survey.id);
                _.remove(this.surveys, { id: survey.id });
                this.debounceFilterSurveys();
                this.$buefy.toast.open({
                    message: survey.name + ' has been deleted',
                    type: 'is-success'
                });
            } catch (error) {
                this.errorMessage = error.message;
            }
        },
        showConfirmDeleteModal (survey) {
            this.$buefy.dialog.confirm({
                title: 'Delete Survey',
                message: this.$sanitize(`This will delete your Survey <b>${survey.name}</b>.<br>Do you wish to continue?`),
                type: 'is-danger',
                confirmText: 'Delete',
                cancelText: 'Cancel',
                hasIcon: true,
                onConfirm: () => this.deleteSurvey(survey)
            });
        },
        async downloadSurveyXmlFile (surveyId) {
            if (surveyId) {
                try {
                    this.isDownloading = true;
                    const response = await surveyXmlFileService.downloadSurveyXmlFile(surveyId);
                    const suggestedFileName = response.headers['x-suggested-filename'];
                    const fileName = suggestedFileName || 'survey.xml';
                    saveAs(response.data, fileName);
                } catch (error) {
                    this.errorMessage = error.message;
                } finally {
                    this.isDownloading = false;
                }
            }
        }
    },
    watch: {
        filterTerm: function (newValue, oldValue) {
            this.debounceFilterSurveys();
        }
    },
    computed: {
        pageInfo: function () {
            const totalSurveys = this.filteredSurveys.length;
            const startRange = (this.currentPage - 1) * this.surveysPerPage + 1;
            const endRange = Math.min(this.currentPage * this.surveysPerPage, totalSurveys);
            return `Showing ${startRange} to ${endRange} of ${totalSurveys} surveys`;
        }
    }
};
</script>
