import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const allowAnonymous = ['login', 'logout', 'lionlogin', 'passwordchange', 'forgottenpassword'];

const enterIfAdmin = (to, from, next) => {
    const role = localStorage.getItem('role');
    if (role === 'Admin') {
        next();
    }
    next(false);
};

// const enterIfPowerUserOrAbove = (to, from, next) => {
//     const role = localStorage.getItem('role');
//     if (role === 'Admin' || role === 'LocalAdmin' || role === 'PowerUser') {
//         next();
//     }
//     next(false);
// };

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/forgottenpassword',
    //     name: 'forgottenpassword',
    //     component: () => import(/* webpackChunkName: "login" */ '../views/ForgottenPassword.vue')
    // },
    // {
    //     path: '/passwordchange',
    //     name: 'passwordchange',
    //     component: () => import(/* webpackChunkName: "login" */ '../views/PasswordChange.vue')
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        props: true
    },
    {
        path: '/lionlogin',
        name: 'lionlogin',
        component: () => import(/* webpackChunkName: "login" */ '../views/LionLogin.vue')
    },
    {
        path: '/admin',
        // Create a container component with router-view in order to allow nested routing for Admin section
        component: { render: createElement => createElement('router-view') },
        children: [
            {
                path: '',
                name: 'admin',
                component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
            },
            {
                path: 'users',
                name: 'users',
                component: () => import(/* webpackChunkName: "admin" */ '../views/Users.vue')
            },
            {
                path: 'logs',
                name: 'logs',
                component: () => import(/* webpackChunkName: "admin" */ '../views/Logs.vue')
            },
            {
                path: 'docs',
                name: 'docs',
                component: () => import(/* webpackChunkName: "admin" */ '../views/Documents.vue')
            }
        ],
        beforeEnter: enterIfAdmin
    },
    {
        path: '/survey',
        component: { render: createElement => createElement('router-view') },
        children: [
            {
                path: '',
                name: 'surveyCreate',
                component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyScaffold.vue')
            },
            {
                path: ':surveyId',
                name: 'surveyEdit',
                component: () => import(/* webpackChunkName: "survey" */ '../views/SurveyScaffold.vue'),
                props: (route) => ({ surveyId: parseInt(route.params.surveyId, 10) })
            }
        ]
    },

    // Matchall should be used for 404 page.
    {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach((to, from, next) => {
    if (!allowAnonymous.find(s => s === to.name)) {
        if (localStorage.getItem('jwt') === null) {
            // use push instead of next to provide route params
            router.push({
                name: 'login',
                params: { nextUrl: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
