const APP_VERSION = 'p-release-r19';

const CURRENT_PROJECT_VERSION = 2;

const SURVEY_STATE = {
    IN_PROGRESS: 'InProgress',
    COMPLETE: 'Complete'
};

export default {
    APP_VERSION: APP_VERSION,
    CURRENT_PROJECT_VERSION: CURRENT_PROJECT_VERSION,
    SURVEY_STATE: SURVEY_STATE
};
