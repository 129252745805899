import axios from '@/services/axios-auth-wrapper';

export default {
    getAll: () => {
        return axios.get(url);
    },
    create: data => {
        return axios.post(url, data);
    },
    delete: id => {
        return axios.delete(url + id);
    }
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/document/';
