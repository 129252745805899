<template>
  <div class="home">
    <div class="level is-vcentered">
        <div class="level-left">
            <h1 class="title is-size-4">Surveys</h1>
        </div>
        <div class="level-right">
            <router-link class="level-item button is-primary is-outlined" to="/survey">Create Survey</router-link>
            <router-link v-if="$can('manage','Admin')" class="level-item button is-outlined" to="/Admin">Admin</router-link>
        </div>
    </div>
    <SurveysTable style="padding-top:1em"/>
    <div class="img-footer has-text-centered">
      <img alt="Publicis Logo" :src="require('@/assets/pm-logo.png')">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SurveysTable from '@/components/SurveysTable.vue';

export default {
    name: 'Home',
    components: {
        SurveysTable
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div.img-footer {
  padding-top: 2em;
}
.level {
  margin-bottom: 0px;
}
</style>
