import Vue from 'vue';
import { abilitiesPlugin } from '@casl/vue';
import Buefy from 'buefy';
import Icon from 'vue-awesome/components/Icon';
import { dateHelperService } from '@/services/date-helper-service';
import VueSanitize from 'vue-sanitize';

// import all icons as we don't currently care about bundle size.
// NB. this can be modified to only include specified icons, see https://github.com/Justineo/vue-awesome for detail
import 'vue-awesome/icons';

import App from './App.vue';
import router from './router';
import store from './store';
import ability from './ability';

Vue.use(Buefy, { defaultIconPack: 'fas' });

// Initialise vue-sanitize for html fragment sanitisation, see https://www.raymondcamden.com/2019/11/26/sanitizing-html-in-vuejs for detail
Vue.use(VueSanitize);

Vue.component('v-icon', Icon);

Vue.filter('formatDate', dateHelperService.formatForClient);

// Initialize Vue with user role abilities.
// This is required here otherwise a user will lose abilities when refreshing the app.
// `ability[role]` must use our custom subjectName function defined in ability.js.
// Ability permissions get updated once users login.
const role = localStorage.getItem('role') || 'Unauthorised';
Vue.use(abilitiesPlugin, ability[role]);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
