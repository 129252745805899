import axios from '@/services/axios-auth-wrapper';

export default {
    getSurveys: () => {
        return axios.get(url);
    },
    getSurvey: (surveyId) => {
        return axios.get(url + surveyId);
    },
    updateSurvey: (data) => {
        return axios.post(url + 'update', data);
    },
    addSurvey: (data) => {
        return axios.post(url + 'create', data);
    },
    deleteSurvey: (id) => {
        return axios.delete(url + id);
    }
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/survey/';
