import axios from 'axios';
import { EventBus } from '@/event-bus';

const authedInstance = axios.create();

authedInstance.interceptors.request.use(
    config => {
        config.headers.authorization = 'bearer ' + localStorage.getItem('jwt');
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

authedInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response) {
            if (error.response.status === 403) {
                error.message = 'You do not have permission to view or edit this content';
            }
            if (error.response.status === 401) {
                // log user out and redirect to login when Unauthorized
                EventBus.$emit('logout');
            }
        }

        return Promise.reject(error);
    }
);

export default authedInstance;
