import { Ability, defineAbility } from '@casl/ability';

// TODO: Test this different way of constructin abilites.
// NB: We're useing casl/vue:1.2.2, casl/vue:2+ is written for Vue3

// To allow CASL to work with anonymous object we need to change subject detection function
// Either need to detect Object type by its structure or add additional field inside to detect type.
// https://github.com/stalniy/casl/issues/153

const detectAnonymousSubjectType = subject => subject || typeof subject === 'string'
    ? subject
    : subject.__caslType;

// Also need to adjust how our abilities are created.
const Admin = defineAbility((can) => {
    can('manage', 'Admin');
    can('manage', 'Users');
    can('manage', 'Logs');
    can('manage', 'Documents');
    // can('manage', 'HFMCodes');
    // can('manage', 'ProjectOwnership');
    can('download', 'SurveyFiles');
    can(['create', 'read', 'update', 'delete'], 'Survey');
}, {
    detectSubjectType: detectAnonymousSubjectType
});

const PowerUser = defineAbility((can) => {
    can(['create', 'read'], 'Survey');
    can(['update', 'delete'], 'Survey', { userId: parseInt(localStorage.getItem('userId'), 10) });
}, {
    detectSubjectType: detectAnonymousSubjectType
});

const User = defineAbility((can) => {
    // can(['create', 'read'], 'Survey');
}, {
    detectSubjectType: detectAnonymousSubjectType
});

// Define an empty ability object that uses the custom subjectName() for default/unauthorised users
const Unauthorised = new Ability([], { detectAnonymousSubjectType });

export default { Admin, PowerUser, User, Unauthorised };
